import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Injectable, InjectionToken, NgModule, NgZone, Optional, Output, Self, ViewChild } from '@angular/core';
import { NgControl } from '@angular/forms';

const RECAPTCHA_CONFIG = new InjectionToken('angular-google-recaptcha siteKey');

class ScriptLoaderService {
    /**
     * @param {?} config
     * @return {?}
     */
    injectAndLoadScript(config) {
        const /** @type {?} */ script = document.createElement('script');
        script.src = config.scriptSrc;
        script.async = true;
        script.defer = true;
        script.onload = () => config.onLoadCallback();
        script.onerror = err => config.onErrorCallback(err);
        document.body.appendChild(script);
    }
}
ScriptLoaderService.decorators = [
    { type: Injectable },
];
/**
 * @nocollapse
 */
ScriptLoaderService.ctorParameters = () => [];
class RecaptchaComponent {
    /**
     * @param {?} recaptchaConfig
     * @param {?} controlDir
     * @param {?} scriptLoaderService
     * @param {?} zone
     * @param {?} cd
     */
    constructor(recaptchaConfig, controlDir, scriptLoaderService, zone, cd) {
        this.recaptchaConfig = recaptchaConfig;
        this.controlDir = controlDir;
        this.scriptLoaderService = scriptLoaderService;
        this.zone = zone;
        this.cd = cd;
        this.scriptLoad = new EventEmitter();
        this.scriptError = new EventEmitter();
        this.GLOBAL_ON_LOAD_CALLBACK_NAME = '___recaptchaOnLoadCallback___';
        this.controlDir.valueAccessor = this;
    }
    /**
     * @return {?}
     */
    ngOnInit() {
        const /** @type {?} */ control = this.controlDir.control;
        if (!control) {
            return;
        }
        this.setGlobalHandlers();
        this.injectGoogleRecaptchaScript();
        /**
         * Only one validator (specifically our one below) makes sense for this Control, so we just overwrite
         * whatever was previously set
         */
        control.setValidators((ctrl) => {
            if (typeof this.activeRecaptchaId === 'undefined' || !this.recaptchaAPI) {
                return {
                    invalidRecaptcha: true,
                };
            }
            const /** @type {?} */ recaptchaResponse = this.recaptchaAPI.getResponse(this.activeRecaptchaId);
            if (!recaptchaResponse) {
                return {
                    invalidRecaptcha: true,
                };
            }
            return null;
        });
        control.updateValueAndValidity();
    }
    /**
     * @return {?}
     */
    ngOnDestroy() {
        this.unsetGlobalHandlers();
    }
    /**
     * There is currently no way to programmatically set the value of
     * a visible reCAPTCHA, so this is a noop
     * @param {?} val
     * @return {?}
     */
    writeValue(val) { }
    /**
     * Required method of the ControlValueAccessor interface, we register the callback
     * function that should be called whenever the model value changes
     * @param {?} fn
     * @return {?}
     */
    registerOnChange(fn) {
        this.onChange = fn;
    }
    /**
     * Required method of the ControlValueAccessor interface, we register the callback
     * function that should be called whenever the control is "touched"
     * @param {?} fn
     * @return {?}
     */
    registerOnTouched(fn) {
        this.onTouched = fn;
    }
    /**
     * Unfortunately we have to register a global handler for the onload
     * event from the recaptcha lib
     * @return {?}
     */
    setGlobalHandlers() {
        ((window))[this.GLOBAL_ON_LOAD_CALLBACK_NAME] = () => {
            /**
             * Make it easier to add type information to, and work with, the recaptcha lib
             * by storing a single reference to it
             */
            this.recaptchaAPI = ((window)).grecaptcha;
            this.renderRecaptcha();
        };
    }
    /**
     * @return {?}
     */
    unsetGlobalHandlers() {
        delete ((window))[this.GLOBAL_ON_LOAD_CALLBACK_NAME];
    }
    /**
     * Create a <script> element and inject it into the page in order
     * to load the recaptcha lib. Emit load or error events from the relevant
     * Outputs to the component
     * @return {?}
     */
    injectGoogleRecaptchaScript() {
        this.scriptLoaderService.injectAndLoadScript({
            scriptSrc: `https://www.google.com/recaptcha/api.js?render=explicit&onload=${this.GLOBAL_ON_LOAD_CALLBACK_NAME}`,
            onLoadCallback: () => this.scriptLoad.emit(),
            onErrorCallback: err => this.scriptError.emit(err),
        });
    }
    /**
     * Use the recaptcha lib to manually render a recaptcha widget with the ViewChild
     * container element, passing the relevant callbacks and configuration options
     * @return {?}
     */
    renderRecaptcha() {
        if (!this.recaptchaAPI) {
            return;
        }
        this.activeRecaptchaId = this.recaptchaAPI.render(this.container.nativeElement, {
            sitekey: this.recaptchaConfig.siteKey,
            callback: this.onRecaptchaValidCallback.bind(this),
            'expired-callback': this.onRecaptchaExpiredCallback.bind(this),
        });
    }
    /**
     * Handler which will be registered with the recaptcha lib to be called
     * whenever it has a valid status
     * @return {?}
     */
    onRecaptchaValidCallback() {
        this.zone.run(() => {
            this.onChange(true);
            this.onTouched();
            this.cd.markForCheck();
        });
    }
    /**
     * Handler which will be registered with the recaptcha lib to be called
     * whenever its valid status expires
     * @return {?}
     */
    onRecaptchaExpiredCallback() {
        this.zone.run(() => {
            this.onChange(false);
            this.cd.markForCheck();
        });
    }
}
RecaptchaComponent.decorators = [
    { type: Component, args: [{
                selector: 'recaptcha',
                changeDetection: ChangeDetectionStrategy.OnPush,
                template: `
    <div class="angular-google-recaptcha-container" #container></div>
  `,
                providers: [ScriptLoaderService],
            },] },
];
/**
 * @nocollapse
 */
RecaptchaComponent.ctorParameters = () => [
    { type: undefined, decorators: [{ type: Inject, args: [RECAPTCHA_CONFIG,] },] },
    { type: NgControl, decorators: [{ type: Self }, { type: Optional },] },
    { type: ScriptLoaderService, },
    { type: NgZone, },
    { type: ChangeDetectorRef, },
];
RecaptchaComponent.propDecorators = {
    'scriptLoad': [{ type: Output },],
    'scriptError': [{ type: Output },],
    'container': [{ type: ViewChild, args: ['container',] },],
};

class RecaptchaModule {
    /**
     * @param {?} recaptchaConfig
     * @return {?}
     */
    static forRoot(recaptchaConfig) {
        return {
            ngModule: RecaptchaModule,
            providers: [{ provide: RECAPTCHA_CONFIG, useValue: recaptchaConfig }],
        };
    }
}
RecaptchaModule.decorators = [
    { type: NgModule, args: [{
                declarations: [RecaptchaComponent],
                exports: [RecaptchaComponent],
            },] },
];
/**
 * @nocollapse
 */
RecaptchaModule.ctorParameters = () => [];

/**
 * Generated bundle index. Do not edit.
 */

export { RecaptchaModule, ScriptLoaderService, RecaptchaComponent, RECAPTCHA_CONFIG };
